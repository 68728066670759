import { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";

import DollarCircle from '../../components/icons/dollar-circle.svg';
import LayerGroup from '../../components/icons/layer-group.svg';
import Tag from '../../components/icons/tag.svg';
import ArrowRight from '../../components/icons/arrow-right.svg';
import ArrowLeft from '../../components/icons/arrow-left.svg';

import Deals from '../../components/icons/category/deals.svg';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/free-mode";
import { Navigation, FreeMode, Scrollbar } from "swiper";

import Button from "../../components/Button";
import styles from "./Specials.module.scss";
import SkeletonElement from "../../components/skeletons/SkeletonElement";
import SkeletonDealCard from "../../components/skeletons/SkeletonDealCard";

// render product and bundle specials, display as grid or swiper (default swiper)
export default function Specials({ type, specials, noIcon }) {

  return (
    <>
        {type === 'grid' ?
          <SpecialsGrid specials={specials} />
          :
          <SpecialsSwiper specials={specials} noIcon={noIcon} />
        }
   
    </>
  );
}

function SpecialsSwiper({ specials, noIcon }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (specials.length > 0) {
      setLoading(false);
    }
  }, [specials]);

  const swiperRef = useRef(null);
  const limitedArray = specials.slice(0, 8);

  return (
    <>
      <div className={styles.swiperWrapper}>
        <div className={styles.header}>
          <div className={styles.title}>
            {!noIcon && <div className={styles.icon}>
              <Deals />
            </div>}
            <h2>Deals</h2>
          </div>
          <Button type="link" href='/deals' className="sliderLink" prefetch={false} ariaLabel="View All Deals">
            <div>view all</div>
          </Button>
        </div>

        <Swiper
          modules={[Navigation, FreeMode, Scrollbar]}
          className={styles.specialsSwiper}
          breakpoints={{
            1024: {
              slidesPerView: 3,
              slidesPerGroup: 3,
              spaceBetween: 16,
            },
            768: {
              slidesPerView: 2,
              slidesPerGroup: 2,
              spaceBetween: 14,
            },
            460: {
              slidesPerView: 1.5,
              slidesPerGroup: 1,
              spaceBetween: 10,
            },
            0: {
              slidesPerView: 1.2,
              slidesPerGroup: 1,
              spaceBetween: 10,
            }
          }}
          touchEventsTarget="container"
          navigation={{
            prevEl: `#specials-prev`,
            nextEl: `#specials-next`,
            clickable: true,
          }}
          scrollbar={{
            hide: true,
          }}
          onSwiper={(swiper) => (swiperRef.current = swiper)}
        >
          <div className={styles.prev} id="specials-prev">
            <Button type="button" className="slider-arrow" ariaLabel="Previous Slide">
              <ArrowLeft />
            </Button>
          </div>
          <div className={styles.next} id="specials-next">
            <Button type="button" className="slider-arrow" ariaLabel="Next Slide">
              <ArrowRight />
            </Button>
          </div>
          {loading ? (
            [...Array(3)].map((_, index) => (
              <SwiperSlide key={index} className={`${styles.slide} bundle-card`}>
                <SkeletonDealCard type="deal" />
              </SwiperSlide>
            ))
          ) : (
            <>
              {limitedArray.map((item, index) => (
                <SwiperSlide key={index} className={`${styles.slide} bundle-card`}>
                  <SpecialItem item={item} />
                </SwiperSlide>
              ))}
              <SwiperSlide className={`${styles.lastSlide}`}>
                <Link href="/deals"><Deals />View All</Link>
              </SwiperSlide>
            </>
          )}
        </Swiper>
      </div>
    </>
  );
}

function SpecialsGrid({ specials }) {

  return (
    <div>
      {!specials.length > 0 ? (
        <div className={styles.grid}>
          {[...Array(12)].map((_, index) => (
            <div key={index}>
              <SkeletonElement type="special" key="index" />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.grid}>
          {specials.map(item => (
            <SpecialItem key={item.id} item={item} />
          ))}
        </div>
      )}
    </div>
  );
}

function SpecialItem({ item }) {
  const { id, title, photo, description, special_type } = item;

  // Determine properties based on special_type
  let href
  let tagText
  let icon

  switch (special_type) {
    case "product":
      href = `/shop?special=${id}`;
      tagText = "Sale";
      icon = <Tag />;
      break;
    case "bundle":
      href = `/deals/bundle/${id}`;
      tagText = "Bundle";
      icon = <LayerGroup />;
      break;
    default:
      break;
  }
  const imageUrl = photo?.urls?.original;

  // Regular expression to match 'tax included' in any case
  const taxIncludedRegex = /tax included/i;
  const hasTaxIncluded = taxIncludedRegex.test(description);

  return (
    <Link href={href} className="bundle-card">
      <div className={styles.imageContainer}>
        {imageUrl ?
          <Image
            src={imageUrl}
            fill
            style={{ objectFit: 'cover' }}
            sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
            alt={title}
          />
          :
          <div className={styles.fallbackTag}>
            {icon}
            <span>{tagText}</span>
          </div>
        }
      </div>
      <div className={styles.content}>
        <div className="text-ellipsis fw-600 pb-1">
          {title}
        </div>
        <div className={styles.tags}>

          <span className={styles.tag}>
            {icon} {tagText}
          </span>

          {hasTaxIncluded && (
            <span className={styles.taxIncluded}>
              <DollarCircle />
              Tax Included
            </span>
          )}
        </div>
      </div>
    </Link>
  );
}

